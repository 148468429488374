import(/* webpackMode: "eager" */ "C:\\Users\\base\\Documents\\Traffix\\front-end\\traffic-fe-landding-customer\\node_modules\\@next\\third-parties\\dist\\google\\ga.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\base\\Documents\\Traffix\\front-end\\traffic-fe-landding-customer\\node_modules\\@next\\third-parties\\dist\\google\\gtm.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\base\\Documents\\Traffix\\front-end\\traffic-fe-landding-customer\\node_modules\\@next\\third-parties\\dist\\ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\base\\Documents\\Traffix\\front-end\\traffic-fe-landding-customer\\node_modules\\next\\dist\\client\\script.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\base\\Documents\\Traffix\\front-end\\traffic-fe-landding-customer\\node_modules\\next\\font\\google\\target.css?{\"path\":\"src\\\\app\\\\fonts\\\\fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\base\\Documents\\Traffix\\front-end\\traffic-fe-landding-customer\\src\\providers\\ReactI18nextProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\base\\Documents\\Traffix\\front-end\\traffic-fe-landding-customer\\src\\providers\\ReactQueryProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\base\\Documents\\Traffix\\front-end\\traffic-fe-landding-customer\\src\\providers\\RecoilProvicer.tsx");
;
import(/* webpackMode: "eager" */ "C:\\Users\\base\\Documents\\Traffix\\front-end\\traffic-fe-landding-customer\\src\\styles\\index.css");
